import { useEffect, useState, useCallback, useRef } from "react";
import { CircularProgress, Box, Alert, AlertTitle } from "@mui/material";
import axios from "axios";
import DataTable from "../../components/DataTable";
import CommandMessages from "../../components/CommandMessages";
import ReportChart from "../../components/ReportChart";
import { seo } from "../../util/seo";
import "react-toastify/dist/ReactToastify.css";
import { FETCH_MESSAGE_STATS_URL, FETCH_MESSAGES_URL, useRelatoryData, useRelatoryStatData } from "../../hooks/relatoryHooks";
import { mutate } from "swr";

export default function App() {
  return <QueryParams />;
}

function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

function QueryParams() {
  const [loadingResend, setLoadingResend] = useState(false);

  const [statusFilter, setStatusFilter] = useState("all");
  const [searchValue, setSearchValue] = useState("");

  const [loadingExport, setLoadingExport] = useState(false);
  const [timeInterval, setTimeInterval] = useState(0);

  const [messages, setMessages] = useState({
    messages: [],
  });
  const [statistics, setStatistics] = useState({
    success: 0,
    queue: 0,
    error: 0,
    otherFailure: 0,
    sent: 0,
    received: 0,
    seen: 0,
  });

  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateInterval, setDateInterval] = useState([null, null]);
  const [automaticallyFetchMessages, setAutomaticallyFetchMessages] =
    useState(false);
  const [aviso, setAviso] = useState([]);
  const autoFetchIntervalId = useRef(undefined);

  const relatoryData = useRelatoryData(
    getParam("api"),
    getParam("secret"),
    (currentPage - 1) * itemsPerPage,
    itemsPerPage,
  );
  const statData = useRelatoryStatData(
    getParam("api"),
    getParam("secret"),
    0,
    10001,
  );

  const invalidateMessages = () => {
    mutate(FETCH_MESSAGES_URL);
    mutate(FETCH_MESSAGE_STATS_URL);
  };

  useEffect(() => {
    if (!relatoryData.isLoading && relatoryData.data) {
      setMessages(relatoryData.data);
    }
  }, [relatoryData.data]);

  useEffect(() => {
    setStatistics(statData.data);
  }, [statData.data]);

  useEffect(() => {
    if (automaticallyFetchMessages) {
      if (autoFetchIntervalId.current) {
        window.clearInterval(autoFetchIntervalId.current);
      }
      autoFetchIntervalId.current = window.setInterval(() => {
        invalidateMessages();
      }, 10000);
    } else {
      if (autoFetchIntervalId.current) {
        window.clearInterval(autoFetchIntervalId.current);
      }
    }
  }, [automaticallyFetchMessages]);

  useEffect(() => {
    invalidateMessages();
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    seo({
      title: "Relatório",
      metaDescription: "With some meta description",
    });
    getAviso();
  }, []);
  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 10000);

  const getAviso = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL_API}/notifications`)
      .then((res) => {
        setAviso(res.data);
      });
  };

  const handleRequestExportReport = (data) => {
    const messageLimit = data.maxMessageNumber ?? 1000;
    const { phoneLike, messageLike, dateInterval } = data;

    setLoadingExport(true);

    const downloadAsTable = (messages) => {
      const csvBlob = new Blob([
        messages
          .map(m => {
            let ack = "not_sent";
            switch (m.ack) {
              case 0:
                ack = "not_sent";
                break;
              case 1:
                ack = "sent";
                break;
              case 2:
                ack = "read";
                break;
            }
            return {
              phone: m.phone,
              message: `${JSON.stringify(m.message)}`,
              sendAt: m.sendAt,
              ack,
              status: m.status,
            };
          })
          .map(message => `"=""${message.phone}""",${message.message},${message.sendAt},${message.ack},${message.status}`)
          .reduce((acc, current) => `${acc}\n${current}`, "SEP=,\nphone,message,sent_at,ack,status")
      ], { type: "text/csv" });

      const downloadLink = document.createElement('a');

      downloadLink.download = 'report.csv';
      downloadLink.href = window.URL.createObjectURL(csvBlob);
      downloadLink.style.display = 'none';

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(downloadLink.href);
    };

    const url = `${
      process.env.REACT_APP_BASE_URL_API
    }/messages/microservices/messages`;

    let params = {
      api: getParam("api"),
      secret: getParam("secret"),
      offset: 0,
      limit: messageLimit,
    };

    if (messageLike) {
      params.messageLike = messageLike;
    }
    if (phoneLike) {
      params.phoneLike = phoneLike;
    }
    if (dateInterval?.length === 2) {
      dateInterval[0].setHours(0, 0, 0, 0);
      dateInterval[1].setHours(24, 0, 0, 0);

      params.dateBegin = dateInterval[0].toISOString();
      params.dateEnd = dateInterval[1].toISOString();
    }

    var options = {
      method: "GET",
      url: url,
      params,
    };

    axios
      .request(options)
      .then(function (response) {
        downloadAsTable(response.data.messages);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  return (
    <>
      {aviso.map(
        (item) =>
          item.isActive && (
            <Alert severity={item.type} style={{ marginTop: 15 }}>
              <AlertTitle>{item.title}</AlertTitle>
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </Alert>
          )
      )}
      <CommandMessages getMessages={() => {
        invalidateMessages();
      }} />
      <ReportChart />

      {relatoryData.isValidating ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: 600,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress></CircularProgress>
        </Box>
      ) : (
        <DataTable
          loadingResend={loadingResend}
          messages={messages}
          statistics={statistics}
          loadingMessages={relatoryData.isLoading}
          Filter={invalidateMessages}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          dateInterval={dateInterval}
          setDateInterval={setDateInterval}
          setItemsPerPage={setItemsPerPage}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          automaticallyFetchMessages={automaticallyFetchMessages}
          setAutomaticallyFetchMessages={setAutomaticallyFetchMessages}
          onRequestExportReport={handleRequestExportReport}
          loadingExport={loadingExport}
        />
      )}
    </>
  );
}
