import React, { useState, useEffect, useRef } from "react";
import {
  Pagination,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Badge,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Button,
  Tooltip,
  Alert,
  Dialog,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { DateRangePicker, LocalizationProvider, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { ptBR } from "date-fns/locale";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Check,
  ErrorOutline,
  AccessTime,
  Block,
  DeleteOutline,
  KeyboardDoubleArrowRight,
  Close,
  Download,
} from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CardMessage from "../CardMessage";
import { PhoneNumberInput } from "../PhoneNumberInput";
import TextArea from "antd/es/input/TextArea";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [status, setStatus] = useState(row.status);

  const handleDeleteMessage = async (row) => {
    setLoading(true);
    const options = {
      method: "DELETE",
      url: `${process.env.REACT_APP_BASE_URL_API}/messages/microservices/delete/${row._id}`,
      headers: { "Content-Type": "application/json" },
      data: { clientApi: getParam("api"), clientSecret: getParam("secret") },
    };
    await axios
      .request(options)
      .then(function (response) {
        setTimeout(() => {
          setLoading(false);
          setIsVisible(false);
        }, 1000);
      })
      .catch(function (error) {
        console.error(error);
        setIsVisible(false);
      });
  };

  const [isResendingMessage, setIsResendingMessage] = useState(false);
  const handleResendMessage = async (row) => {
    setIsResendingMessage(true);
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/messages/microservices/resendMessage/${row._id}`,
      headers: { "Content-Type": "application/json" },
      data: { clientApi: getParam("api"), clientSecret: getParam("secret") },
    };
    await axios
      .request(options)
      .then(function (response) {
        setTimeout(() => {
          setIsResendingMessage(false);
          setStatus("queue");
          props.setResendAlert({
            open: true,
            severity: "success",
            message: "Mensagem adicionada a fila novamente",
          });
        }, 1000);
      })
      .catch(function (error) {
        setIsResendingMessage(false);
        console.error(error);
        props.setResendAlert({
          open: true,
          severity: "error",
          message: "Não foi possível reenviar a mensagem",
        });
      });
  };

  const optionDate = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  const getStateSendMensseger = (state) => {
    switch (state) {
      case 1:
        return (
          <Tooltip title="Mensagem não recebida!">
            <Check color="disabled" />
          </Tooltip>
        );
      case 2:
        return (
          <Tooltip title="Mensagem recebida!">
            <DoneAllIcon color="disabled" />
          </Tooltip>
        );
      case 3:
        return (
          <Tooltip title="Mensagem lida!">
            <DoneAllIcon color="primary" />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Enviado com sucesso!">
            <Check color="success" />
          </Tooltip>
        );
    }
  };

  return (
    <>
      {isVisible && (
        <>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row" size="medium">
              <Tooltip title="Deletar mensagem do relatório">
                <LoadingButton
                  size="small"
                  color="error"
                  onClick={() => handleDeleteMessage(row)}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<DeleteOutline />}
                ></LoadingButton>
              </Tooltip>
            </TableCell>
            <TableCell component="th" scope="row">
              <Tooltip title="Reenviar mensagem">
                <LoadingButton
                  size="small"
                  color="info"
                  onClick={() => handleResendMessage(row)}
                  loading={isResendingMessage}
                  loadingPosition="start"
                  startIcon={<KeyboardDoubleArrowRight />}
                />
              </Tooltip>
            </TableCell>
            <TableCell component="th" scope="row">
              <Badge color="info" badgeContent={row._id.substr(-5)} />
            </TableCell>
            <TableCell>
              {new Date(row.created_at).toLocaleDateString("pt-br", optionDate)}
            </TableCell>
            <TableCell>
              {status === "success" && getStateSendMensseger(row.ack)}
              {status === "queue" && (
                <Tooltip title="Em fila">
                  <AccessTime color="info" />
                </Tooltip>
              )}
              {status === "erro" && (
                <Tooltip title="Envio com erro!">
                  <ErrorOutline color="error" />
                </Tooltip>
              )}
              {status === "blocked_contact" && (
                <Tooltip title="Contato bloqueado!">
                  <Block color="error" />
                </Tooltip>
              )}
            </TableCell>
            <TableCell>{row.phone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Conteúdo
                  </Typography>
                  <CardMessage row={row} />
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

function DataTable(props) {
  const [withinWorkingHours, setWithinWorkingHours] = React.useState(true);
  const [userPlanFunctionalities, setUserPlanFunctionalities] = React.useState(
    []
  );
  const [resendAlert, setResendAlert] = useState({
    open: false,
    message: "mensagem",
    severity: "info",
  });
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [showExportReportModal, setShowExportReportModal] = useState(false);
  const defaultDateRange = [new Date(), new Date()];
  defaultDateRange[0].setHours(0, 0, 0, 0);
  defaultDateRange[1].setHours(23, 59, 59, 999);
  const [exportFormData, setExportFormData] = useState({
    dateInterval: defaultDateRange,
    phoneLike: null,
    messageLike: null,
    maxMessageNumber: 1000,
  });

  let statistics = {
    success: 0,
    queue: 0,
    error: 0,
    otherFailure: 0,
    sent: 0,
    received: 0,
    seen: 0,
  };

  if (props.statistics?.statistics) {
    statistics = props.statistics.statistics;
  }

  const closeResendAlert = () => {
    setResendAlert({ ...resendAlert, open: false });
  };

  useEffect(() => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/informations`,
      headers: { "Content-Type": "application/json" },
      data: { clientApi: getParam("api"), clientSecret: getParam("secret") },
    };

    axios
      .request(options)
      .then(function (response) {
        setWithinWorkingHours(
          response.data.officeHourService.withinWorkingHours
        );
        setUserPlanFunctionalities(response.data.user.plan.functionalities);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  const handleChangeItemsPerPage = (event) => {
    props.setItemsPerPage(event.target.value);
  };

  const handleChangeCurrentPage = (event, page) => {
    props.setCurrentPage(page);
  };

  const handleChangeAutomaticallyFetchMessages = (event) => {
    props.setAutomaticallyFetchMessages(event.target.checked);
  };

  const handleExportReport = () => {
    setShowExportReportModal(true);
  };

  const stylePaper = {
    margin: 10,
    padding: 20,
  };

  return (
    <Paper elevation={6} style={stylePaper}>
      {!withinWorkingHours && (
        <Alert severity="warning" style={{ marginTop: 15, marginBottom: 15 }}>
          Essa instância não está no seu expediente de trabalho, por isso não
          está fazendo envios agora!
        </Alert>
      )}
      {resendAlert.open && (
        <Alert
          severity={resendAlert.severity}
          onClose={() => closeResendAlert()}
          style={{ marginTop: 15, marginBottom: 15 }}
        >
          {resendAlert.message}
        </Alert>
      )}

      <Dialog open={showExportReportModal}>
        <Paper
          sx={{
            padding: "10px 10px 20px 10px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} direction="column" align="right">
              <IconButton onClick={() => setShowExportReportModal(false)}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={12} align="center">
              <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="Data inicial"
                  endText="Data final"
                  value={exportFormData.dateInterval}
                  onChange={(newValue) => {
                    setExportFormData({
                      ...exportFormData,
                      dateInterval: newValue,
                    })
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} fullWidth/>
                      <Box sx={{ mx: 2 }}> até </Box>
                      <TextField {...endProps} fullWidth/>
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField label="Telefone" fullWidth onChange={(newValue) => setExportFormData({...exportFormData, phoneLike: newValue})}></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField type="number" label="Número máximo de mensagens" fullWidth onChange={(newValue) => setExportFormData({...exportFormData, maxMessageNumber: Math.floor(newValue.target?.value)})}>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                label="Busca de texto da mensagem"
                onChange={(newValue) => {
                  setExportFormData({
                    ...exportFormData,
                    messageLike: newValue,
                  });
                }}
            ></TextField>
            </Grid>
            <Grid item xs></Grid>
            <Grid item>
              <Button onClick={() => setShowExportReportModal(false)}>Cancelar</Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  setShowExportReportModal(false);
                  if (props.onRequestExportReport) {
                    props.onRequestExportReport(exportFormData);
                  }
              }}>
                Exportar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
      <Dialog open={filterDialogOpen}>
        <Paper
          sx={{
            padding: "10px 10px 20px 10px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} direction="column" align="right">
              <IconButton onClick={() => setFilterDialogOpen(false)}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="Data inicial"
                  endText="Data final"
                  value={props.dateInterval}
                  onChange={(newValue) => {
                    props.setDateInterval(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> até </Box>
                      <TextField {...endProps} />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <TextField
                fullWidth
                label="N° de Telefone, Texto da Mensagem, Link de arquivo e etc..."
                value={props.searchValue}
                id="fullWidth"
                onChange={(ev) => props.setSearchValue(ev.target.value)}
              />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs></Grid>
              <Grid item xs={3} align="right">
                <Button
                  onClick={() => {
                    props.setStatusFilter("all");
                    props.setSearchValue("");
                    props.setCurrentPage(1);
                    props.setDateInterval([null, null]);
                    props.setItemsPerPage(30);
                    props.Filter();
                  }}
                  variant="outlined"
                  size="large"
                >
                  Limpar
                </Button>
              </Grid>
              <Grid item xs={3} align="right">
                <Button
                  onClick={() => {
                    props.Filter();
                    setFilterDialogOpen(false);
                  }}
                  variant="contained"
                  size="large"
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>

      <Grid container columns={24} alignItems={"center"}>
        <Grid item xs={8}>
          <FormControl
            display="inline"
            variant="standard"
            sx={{ m: 1, minWidth: 160 }}
          >
            <InputLabel id="demo-simple-select-standard-label">
              Mensagens por página
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={props.itemsPerPage}
              onChange={handleChangeItemsPerPage}
            >
              <MenuItem value={10}>10 Mensagens</MenuItem>
              <MenuItem value={30}>30 Mensagens</MenuItem>
              <MenuItem value={50}>50 Mensagens</MenuItem>
              <MenuItem value={100}>100 Mensagens</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            display="inline"
            variant="standard"
            sx={{ m: 1, minWidth: 160 }}
          >
            <InputLabel id="demo-simple-select-standard-label">
              Status da mensagem
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={props.statusFilter}
              onChange={(ev) => {
                props.setStatusFilter(ev.target.value);
              }}
              label={""}
            >
              <MenuItem value={"all"}>Todas</MenuItem>
              <MenuItem value={"success"}>Enviada</MenuItem>
              <MenuItem value={"queue"}>Em Fila</MenuItem>
              <MenuItem value={"erro"}>Com erro</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <div style={{ marginLeft: "30%" }} /> */}
        <Grid item xs={3} justifyContent={"center"}>
          <Button variant="outlined" onClick={() => setFilterDialogOpen(true)}>
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeAutomaticallyFetchMessages}
                checked={props.automaticallyFetchMessages}
              />
            }
            label="Atualizar relatório automático"
          />
        </Grid>
        <Grid item xs={5}>
          <Box sx={{ m: 2 }}>
            <Tooltip title="Enviado com sucesso!">
              <Badge
                badgeContent={statistics.success}
                max={100000}
                color="success"
                // sx={{ marginLeft: 10 }}
              >
                <CheckIcon color="success" />
              </Badge>
            </Tooltip>
            <Tooltip title="Em fila!">
              <Badge
                max={100000}
                badgeContent={statistics.queue}
                color="primary"
                sx={{ marginLeft: "40px" }}
              >
                <AccessTimeIcon color="primary" />
              </Badge>
            </Tooltip>
            <Tooltip title="Envio com erro!">
              <Badge
                badgeContent={statistics.error + statistics.otherFailure}
                max={100000}
                color="error"
                sx={{ marginLeft: "40px" }}
              >
                <ErrorOutlineIcon color="error" />
              </Badge>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={2} justifyContent={"center"}>
          { props.loadingExport ? (
            <CircularProgress/>
          ) : (
            <Button onClick={handleExportReport}>
              <Download/>Exportar
            </Button>
          )}
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />

              <TableCell>Deletar</TableCell>
              <TableCell>Reenviar</TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Adicionado a Fila</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Telefone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.messages.messages.map((row) => (
              <Row
                key={row._id}
                row={{
                  ...row,
                  userCanSendFile: userPlanFunctionalities.includes("SendFile"),
                }}
                setResendAlert={setResendAlert}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props.messages.messages.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: 600,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3>Nenhum registro encontrado!</h3>
        </Box>
      ) : (
        ""
      )}

      <Pagination
        count={Math.ceil(props.messages.count / props.itemsPerPage)}
        showFirstButton
        showLastButton
        page={props.currentPage}
        onChange={handleChangeCurrentPage}
      />
    </Paper>
  );
}

export default DataTable;
