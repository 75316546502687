import axios from "axios";
import useSWR from "swr";

export const FETCH_MESSAGES_URL = `${process.env.REACT_APP_BASE_URL_API}/messages/microservices/messages`;
export const FETCH_MESSAGE_STATS_URL = `${process.env.REACT_APP_BASE_URL_API}/messages/microservices/messageStatistics`;

const fetchData = async (url, api, secret, offset, limit) => {
  const result = await axios.get(url, {
    params: {
      api,
      secret,
      offset,
      limit,
    },
  });

  return result.data;
};

export const useRelatoryData = (api, secret, offset, limit) => {
  const { data, error, isLoading, isValidating } = useSWR(FETCH_MESSAGES_URL, (url) => {
    return fetchData(url, api, secret, offset, limit);
  });

  return { data, error, isLoading, isValidating };
};

export const useRelatoryStatData = (api, secret, offset, limit) => {
  const { data, error, isLoading } = useSWR(FETCH_MESSAGE_STATS_URL, (url) => {
    return fetchData(url, api, secret, offset, limit);
  });

  return { data, error, isLoading };
};