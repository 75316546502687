import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "../src/pages/Home/";
import Relatory from "../src/pages/Relatory/";
import Instance from "../src/pages/Instance/";
import Message from "../src/pages/Mensagem/";
import WorkSchedule from "../src/pages/WorkSchedule";
import Generator from "./pages/Generator";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/Message" element={<Message />} />
            <Route path="/Relatory" element={<Relatory />} />
            <Route path="/Instance" element={<Instance />} />
            <Route path="/WorkSchedule" element={<WorkSchedule />} />
            <Route path="/Generator" element={<Generator />} />

          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
